import { useCallback, useEffect, useState } from "react";

import { Select, Spin, TimePicker } from "antd";
import { DatePicker } from "antd";

import { MdOutlineNoPhotography } from "react-icons/md";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { CategoryType, City, EventType } from "types";

import { useSWRConfig } from "swr";

import moment from "moment";

import { GetAddressByZipCode, useCitiesByUf } from "services/hooks/city";

import { Colors } from "constants/colors";
import { ufs } from "constants/ufs";
import { cep } from "utils/masks";

import {
  Background,
  ButtonRemove,
  ButttonEdit,
  CategoryPill,
  Circle,
  ContainerButtons,
  ContainerCategories,
  ContainerDescription,
  ContainerForm,
  ContainerInput,
  ContainerInputFull,
  FormFull,
  FormFullNormal,
  FormLabel,
  StyledSelect,
} from "./styles";

import Datas from "constants/Datas";

import { LoadingOutlined } from "@ant-design/icons";

import { TextArea } from "components/FormDetails/TextArea";

import editIcon from "assets/pen-green.svg";
import { useAuthAllCategories } from "services/hooks/category";
import { EditEvent, UploadFileEvent } from "services/hooks/event";
import { ADMIN_STATUS, EAdminStatus } from "constants/adminStatus";

import {
  errorNotification,
  successNotification,
  warningNotification,
} from "components/Notification";

const { Option } = Select;

interface Meta {
  total: number;
}

interface CategoriesInterface {
  data: CategoryType[];
  meta: Meta;
}

type EventFormData = {
  name: string;
  description: string;
  begin_date: string;
  end_date: string;
  begin_hour: string;
  end_hour: string;
  privacity: string;
  type_event: number;
  address_name: string;
  address_zipcode: string;
  address_street: string;
  address_country: string;
  address_district: string;
  address_city: string;
  address_state: string;
  address_number: string;
  support_email: string | null;
  support_whatsapp: string | null;
  streaming_id: string;
  streaming_name: string;
  streaming_url: string;
  age: number;
  nickname: string;
  // use_terms: string;
  // require_terms: boolean;
  formattedLinkNick: string;
  admin_status: string;
  admin_status_detail?: string;
};

const eventFormSchema = yup.object().shape({
  name: yup.string().required("Nome obrigatório"),
  description: yup.string().required("Descrição obrigatória"),
  begin_date: yup.string().required("Data obrigatória"),
  end_date: yup.string().required("Data obrigatória"),
  begin_hour: yup.string().required("Data obrigatória"),
  end_hour: yup.string().required("Data obrigatória"),
  privacity: yup.string().required("Privacidade obrigatória"),
  type_event: yup.string().required("Tipo obrigatória"),
  address_name: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Nome obrigatória"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Nome obrigatória"),
    }),
  address_zipcode: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Código obrigatóri"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Código obrigatório"),
    }),
  address_street: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Rua obrigatória"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Rua obrigatório"),
    }),
  address_country: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Pais obrigatório"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Pais obrigatório"),
    }),
  address_district: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Bairro obrigatório"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Bairro obrigatório"),
    }),
  address_city: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Cidade obrigatória"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Cidade obrigatória"),
    }),
  address_state: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Estado obrigatório"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Estado obrigatório"),
    }),
  address_number: yup
    .string()
    .when("type_event", {
      is: "1",
      then: yup.string().required("Número obrigatório"),
    })
    .when("type_event", {
      is: "3",
      then: yup.string().required("Número obrigatório"),
    }),
  age: yup.string().required("Idade obrigatória"),
  support_email: yup.string().notRequired(),
  support_whatsapp: yup.string().notRequired(),
  nickname: yup.string().required("Nome obrigatório"),
  formattedLinkNick: yup.string().notRequired(),
  streaming_id: yup.string().when("type_event", {
    is: "other",
    then: yup.string().required("Id obrigatório"),
  }),
  // streaming_name: yup.string().when("type_event", {
  //   is: "other",
  //   then: yup.string().required("Id obrigatório"),
  // }),
  streaming_url: yup.string().when("type_event", {
    is: "other",
    then: yup.string().required("Id obrigatório"),
  }),
  // use_terms: yup.string().required("Senha obrigatória"),
  // require_terms: yup.string().required("Senha obrigatória"),
  admin_status: yup
    .string()
    .oneOf(["pending", "rejected", "required_changes", "available"])
    .required("Status do administrador obrigatório"),
  admin_status_detail: yup
    .string()
    .when("admin_status", {
      is: "rejected",
      then: yup.string().required("Informe o motivo do status"),
    })
    .when("admin_status", {
      is: "required_changes",
      then: yup.string().required("Informe o motivo do status"),
    }),
});

interface DetailEventProps {
  id: string;
  data: EventType;
}

const dateFormat = "DD/MM/YYYY";
// const monthFormat = "YYYY/MM";
const formatHour = "HH:mm";

export function DetailEvent({ id, data }: DetailEventProps) {
  const { mutate } = useSWRConfig();

  const {
    register,
    handleSubmit,
    reset,
    control,
    setError,
    setValue,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<EventFormData>({
    resolver: yupResolver(eventFormSchema),
  });

  const [isEditing, setIsEditing] = useState(false);
  const [stateByEventSelected, setStateByEventSelected] = useState("");

  const { data: dataCategories } = useAuthAllCategories<CategoriesInterface>();

  const [selectedCategories, setSelectedCategories] = useState<CategoryType[]>(
    data && data?.categories && data?.categories?.length > 0
      ? data?.categories
      : []
  );

  const { data: dataCities } = useCitiesByUf<City[]>(
    stateByEventSelected ? stateByEventSelected : "SP"
  );

  const handleKeyUpCep = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cep(e);
  }, []);

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const antIconImageLoading = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.primary100 }} spin />
  );

  const privacityWatch = watch("privacity");

  useEffect(() => {
    reset({
      name: data?.name,
      description: data?.description,
      address_zipcode: data?.address_zipcode,
      address_district: data?.address_district,
      address_number: data?.address_number,
      address_city: data?.address_city,
      address_country: data?.address_country,
      address_name: data?.address_name,
      address_state: data?.address_state,
      address_street: data?.address_street,
      privacity: data?.privacity ?? undefined,
      type_event: Number(data?.type_event) ?? undefined,
      support_email: data?.support_email ?? "",
      support_whatsapp: data?.support_whatsapp ?? "",
      age: Number(data?.age) ?? undefined,
      begin_date: data?.formattedBeginDate,
      end_date: data?.formattedEndDate,
      begin_hour: data?.begin_hour,
      end_hour: data?.end_hour,
      nickname: data?.nickname,
      streaming_id: data?.streaming_id ?? "",
      streaming_url: data?.streaming_url ?? "",
      formattedLinkNick: `${process.env.REACT_APP_EVENTX_WEBSITE}/${data.nickname}`,
      admin_status: data.admin_status,
      admin_status_detail: data.admin_status_detail,
    });
  }, [data, reset]);

  const [loadingFile, setLoadingFile] = useState(false);

  const onSelectFile = async (e: any) => {
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    try {
      let formData1 = new FormData();
      if (e.target.files[0]) {
        formData1.append("photo", e.target.files[0]);
      }

      setLoadingFile(true);

      await UploadFileEvent(formData1, id);

      setLoadingFile(false);

      mutate(`/backoffice/event/${id}`);
    } catch (err) {
      errorNotification("Erro ao atualizar imagem");
      setLoadingFile(false);
    }
  };

  const handlePutEvent: SubmitHandler<EventFormData> = async ({
    ...dataEvent
  }) => {
    const {
      name,
      description,
      begin_date,
      end_date,
      begin_hour,
      end_hour,
      type_event,
      address_name,
      address_zipcode,
      address_street,
      address_country,
      address_district,
      address_city,
      address_state,
      address_number,
      nickname,
      streaming_id,
      streaming_url,
      support_email,
      support_whatsapp,
      admin_status: adm_status,
      admin_status_detail,
    } = dataEvent;

    const admin_status = adm_status.toLowerCase();

    clearErrors(["admin_status_detail"]);

    if (
      (admin_status === EAdminStatus.REJECTED ||
        admin_status === EAdminStatus.REQUIRED_CHANGES) &&
      !admin_status_detail
    ) {
      setError("admin_status_detail", { message: "Informe o motivo" });

      return;
    }

    const newDateBegin = begin_date.split("/");

    const dateComponentBegin = `${newDateBegin[2]}-${newDateBegin[1]}-${newDateBegin[0]}`;

    const newDateEnd = end_date.split("/");

    const dateComponentEnd = `${newDateEnd[2]}-${newDateEnd[1]}-${newDateEnd[0]}`;

    const streamingName: any = Datas.Streamings.find(
      (streaming) => streaming.value === String(streaming_id)
    );

    const categories = selectedCategories.map((category) => category._id);

    try {
      const credentials = {
        name,
        description,
        begin_date: dateComponentBegin,
        end_date: dateComponentEnd,
        begin_hour,
        end_hour,
        privacity: privacityWatch ? Number(privacityWatch) : undefined,
        type_event: Number(data?.type_event) ?? undefined,
        address_name,
        address_zipcode,
        address_street,
        address_country,
        address_district,
        address_city,
        address_state,
        address_number,
        age: Number(data?.age) ?? undefined,
        support_email,
        support_whatsapp,
        categories,
        nickname,
        ...((String(type_event) === "2" || String(type_event) === "3") && {
          streaming_id: streamingName?.value,
          streaming_name: streamingName?.label,
          streaming_url,
        }),
        admin_status,
        admin_status_detail:
          admin_status_detail &&
          (admin_status === EAdminStatus.REJECTED ||
            admin_status === EAdminStatus.REQUIRED_CHANGES)
            ? admin_status_detail
            : undefined,
      };

      await EditEvent({ id, credentials });

      setIsEditing(false);

      successNotification("Evento atualizado com sucesso");
    } catch (err: any) {
      errorNotification(
        "Ocorreu um erro ao salvar edição",
        JSON.stringify(err?.response?.data?.message ?? "Tente novamente.")
      );
    }
  };

  const typeEvent = watch("type_event");
  const watchAdminStatus = watch("admin_status");

  const shouldRenderReasonField =
    watchAdminStatus === "rejected" ||
    watchAdminStatus === "required_changes" ||
    data?.admin_status_detail === "required_changes" ||
    data?.admin_status_detail === "rejected";
  const addressZipCode = watch("address_zipcode");

  const handleSearchAddress = useCallback(async () => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        try {
          const response = await GetAddressByZipCode({
            zipCode: addressZipCode,
          });

          const { logradouro, bairro, localidade, uf } = response.data;

          if (bairro === undefined) {
            setError("address_zipcode", {
              type: "manual",
              message: "Não foi possivel encontrar o Cep",
            });
            return;
          }
          setValue("address_street", logradouro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_district", bairro, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_city", localidade, {
            shouldValidate: true,
            shouldDirty: true,
          });
          setValue("address_state", uf, {
            shouldValidate: true,
            shouldDirty: true,
          });
        } catch (err) {
          setError("address_zipcode", {
            type: "required",
            message: "Não foi possivel encontrar o Cep",
          });
        }
      }
    }
  }, [addressZipCode, setError, setValue]);

  useEffect(() => {
    if (addressZipCode) {
      if (addressZipCode.length === 9) {
        handleSearchAddress();
      }
    }
  }, [addressZipCode, handleSearchAddress]);

  const handleSelectedCategory = useCallback(
    (category: CategoryType) => {
      const categoryHasSelected = selectedCategories.some(
        (item) => item._id === category._id
      );

      if (categoryHasSelected) {
        const filterCategories = selectedCategories.filter(
          (item) => item._id !== category._id
        );

        setSelectedCategories(filterCategories);

        return;
      }

      if (selectedCategories.length >= 2) {
        warningNotification(
          "Limite de categorias atingido, Caso queira mudar desmarque uma categoria selecionada"
        );

        return;
      }

      setSelectedCategories([...selectedCategories, category]);
    },
    [selectedCategories]
  );

  return (
    <ContainerForm onSubmit={handleSubmit(handlePutEvent)}>
      <ContainerDescription>
        <h4>Sobre o evento</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <FormLabel>
            <h2>Nome do evento</h2>
          </FormLabel>

          <input
            defaultValue={data?.name}
            {...register("name")}
            disabled={!isEditing}
          />

          {errors.name ? (
            <span className="error">Insira um Nome válido</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <TextArea
            label="Descrição"
            defaultValue={data?.description}
            {...register("description")}
            isEditing={isEditing}
            disabled={!isEditing}
            isFull
          />

          {errors.description ? (
            <span className="error">Insira uma descrição válida</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Tipo do evento</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="type_event"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha o tipo"
                  defaultActiveFirstOption
                  disabled={!isEditing}
                  value={value}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    outline: 0,
                    border: `2px solid ${Colors.secondary20}`,
                    borderRadius: "8px",
                    marginTop: "2px",
                  }}
                >
                  {Datas.TypePlace.map((place) => (
                    <Option
                      value={Number(place.value)}
                      key={Number(place.value)}
                    >
                      {place.label}
                    </Option>
                  ))}
                </StyledSelect>
              )}
            />
          </div>

          {errors.type_event ? (
            <span className="error">Insira um tipo valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Privacidade</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="privacity"
              rules={{ required: "Informe a privacidade do evento" }}
              render={({
                field: { onChange, value },
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Escolha o tipo"
                  value={value}
                  disabled={!isEditing}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    outline: 0,
                    border: `2px solid ${Colors.secondary20}`,
                    borderRadius: "8px",
                    marginTop: "2px",
                  }}
                >
                  <Option value="1">
                    Público
                  </Option>

                  <Option value="2">
                    Privado
                  </Option>
                </StyledSelect>
              )}
            />
          </div>

          {errors.privacity ? (
            <span className="error">Insira a privacidade</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInputFull isEditing={isEditing}>
          <FormLabel>
            <h2>Idade</h2>
          </FormLabel>

          <div className="select">
            <Controller
              control={control}
              name="age"
              rules={{ required: "Salutation is required" }}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <StyledSelect
                  bordered={false}
                  labelInValue
                  placeholder="Classificação Indicativa"
                  value={value}
                  disabled={!isEditing}
                  onChange={(e: any) => {
                    onChange(e.value);
                  }}
                  style={{
                    width: "100%",
                    minHeight: "100%",
                    outline: 0,
                    border: `2px solid ${Colors.secondary20}`,
                    borderRadius: "8px",
                    marginTop: "2px",
                  }}
                >
                  {Datas.Ages.map((age) => (
                    <Option value={Number(age.value)} key={Number(age.label)}>
                      {age.label}
                    </Option>
                  ))}
                </StyledSelect>
              )}
            />
          </div>

          {errors.age ? (
            <span className="error">Insira uma faixa valida</span>
          ) : null}
        </ContainerInputFull>
      </FormFull>

      <ContainerDescription>
        <h4>Categoria</h4>

        {isEditing ? (
          <div className="label">
            <span>
              Escolher até <strong>duas</strong> categorias.
            </span>
          </div>
        ) : null}
      </ContainerDescription>

      {!isEditing ? (
        <ContainerCategories>
          {data.categories.map((category) => (
            <CategoryPill>
              <h6>{category?.name}</h6>
            </CategoryPill>
          ))}
        </ContainerCategories>
      ) : null}

      {isEditing ? (
        <ContainerCategories>
          {dataCategories?.data?.map((category) => (
            <CategoryPill
              active={selectedCategories.some(
                (item) => item._id === category._id
              )}
              haveCursorPointer
              onClick={() => handleSelectedCategory(category)}
            >
              <h6>{category?.name}</h6>
            </CategoryPill>
          ))}
        </ContainerCategories>
      ) : null}

      {String(typeEvent) === "2" || String(typeEvent) === "3" ? (
        <>
          <ContainerDescription>
            <h4>Formato de transmissão do evento</h4>
          </ContainerDescription>

          <FormFull>
            <ContainerInputFull isEditing={isEditing}>
              <FormLabel>
                <h2>Plataforma</h2>
              </FormLabel>

              <div className="select">
                <Controller
                  control={control}
                  name="streaming_id"
                  rules={{ required: "Streaming is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Plataforma"
                      value={value}
                      disabled={!isEditing}
                      onChange={(e: any) => {
                        onChange(e.value);
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,
                        border: `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      {Datas.Streamings.map((plataform) => (
                        <Option
                          value={Number(plataform.value)}
                          key={Number(plataform.label)}
                        >
                          {plataform.label}
                        </Option>
                      ))}
                    </StyledSelect>
                  )}
                />
              </div>

              {errors.streaming_id || errors.streaming_name ? (
                <span className="error">Insira uma plataforma valida</span>
              ) : null}
            </ContainerInputFull>
          </FormFull>
        </>
      ) : null}

      {String(typeEvent) === "2" || String(typeEvent) === "3" ? (
        <>
          <ContainerDescription>
            <h4>Insira o link da sua transmissão</h4>
          </ContainerDescription>

          <FormFullNormal>
            <ContainerInputFull isEditing={isEditing}>
              <FormLabel>
                <h2>URL da transmissão</h2>
              </FormLabel>

              <input {...register("streaming_url")} disabled={!isEditing} />

              {errors.streaming_url ? (
                <span className="error">Insira uma url válido</span>
              ) : null}
            </ContainerInputFull>
          </FormFullNormal>
        </>
      ) : null}

      <ContainerDescription>
        <h4>Data e horário</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Data inicial</h2>
          </FormLabel>

          <Controller
            control={control}
            name="begin_date"
            defaultValue=""
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DatePicker
                format={dateFormat}
                value={value ? moment(value, dateFormat) : null}
                disabled={!isEditing}
                onChange={(date: unknown, dateString: string) => {
                  if (!dateString) {
                    onChange(null);
                    return;
                  }

                  onChange(dateString);
                }}
              />
            )}
          />

          {errors.begin_date ? (
            <span className="error">Insira uma data valida</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Data final</h2>
          </FormLabel>

          <Controller
            control={control}
            name="end_date"
            defaultValue=""
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <DatePicker
                format={dateFormat}
                value={value ? moment(value, dateFormat) : null}
                disabled={!isEditing}
                onChange={(date: unknown, dateString: string) => {
                  if (!dateString) {
                    onChange(null);
                    return;
                  }

                  onChange(dateString);
                }}
              />
            )}
          />

          {errors.end_date ? (
            <span className="error">Insira uma data valida</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Horario inicial</h2>
          </FormLabel>

          <Controller
            control={control}
            name="begin_hour"
            defaultValue=""
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TimePicker
                disabled={!isEditing}
                onChange={(date: unknown, timeString: string) => {
                  if (!timeString) {
                    onChange(null);
                    return;
                  }

                  onChange(timeString);
                }}
                value={value ? moment(value, formatHour) : null}
                format={formatHour}
              />
            )}
          />

          {errors.begin_hour ? (
            <span className="error">Insira um horario valido</span>
          ) : null}
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Horario final</h2>
          </FormLabel>

          <Controller
            control={control}
            name="end_hour"
            defaultValue=""
            rules={{ required: "Salutation is required" }}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TimePicker
                disabled={!isEditing}
                onChange={(date: unknown, timeString: string) => {
                  if (!timeString) {
                    onChange(null);
                    return;
                  }

                  onChange(timeString);
                }}
                value={value ? moment(value, formatHour) : null}
                format={formatHour}
              />
            )}
          />

          {errors.end_hour ? (
            <span className="error">Insira um horario valido</span>
          ) : null}
        </ContainerInput>
      </FormFull>

      {data?.type_event !== 2 ? (
        <>
          <ContainerDescription>
            <h4>Endereço</h4>
          </ContainerDescription>

          <FormFull>
            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Nome do endereço</h2>
              </FormLabel>

              <input
                {...register("address_name")}
                defaultValue={data?.address_name}
                disabled={!isEditing}
              />

              {errors.address_name ? (
                <span className="error">Insira um cep valido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Cep</h2>
              </FormLabel>

              <input
                {...register("address_zipcode")}
                defaultValue={data?.address_zipcode}
                onKeyUp={handleKeyUpCep}
                disabled={!isEditing}
              />

              {errors.address_zipcode ? (
                <span className="error">Insira um cep valido</span>
              ) : null}
            </ContainerInput>
          </FormFull>

          <FormFull>
            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Endereço</h2>
              </FormLabel>

              <input
                defaultValue={data?.address_street}
                {...register("address_street")}
                disabled={!isEditing}
              />

              {errors.address_street ? (
                <span className="error">Insira um endereço valido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Bairro</h2>
              </FormLabel>

              <input
                {...register("address_district")}
                defaultValue={data?.address_district}
                disabled={!isEditing}
              />

              {errors.address_district ? (
                <span className="error">Insira um bairro valido</span>
              ) : null}
            </ContainerInput>
          </FormFull>

          <FormFull>
            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Numero</h2>
              </FormLabel>

              <input
                {...register("address_number")}
                defaultValue={data?.address_number}
                disabled={!isEditing}
              />

              {errors.address_number ? (
                <span className="error">Insira um número valido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Estado</h2>
              </FormLabel>

              <div className="select">
                <Controller
                  control={control}
                  name="address_state"
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o estado"
                      value={value}
                      disabled={!isEditing}
                      onChange={(e: any) => {
                        onChange(e.value);
                        setStateByEventSelected(e.value);
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,
                        border: `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      {ufs.map((uf) => {
                        return (
                          <Option value={uf.sigla} key={uf.id}>
                            {uf.sigla}
                          </Option>
                        );
                      })}
                    </StyledSelect>
                  )}
                />
              </div>

              {errors.address_state ? (
                <span className="error">Insira um cidade válida</span>
              ) : null}
            </ContainerInput>
          </FormFull>

          <FormFull>
            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>Cidade</h2>
              </FormLabel>

              <div className="select">
                <Controller
                  control={control}
                  name="address_city"
                  rules={{ required: "Salutation is required" }}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha a cidade"
                      value={value}
                      disabled={!isEditing}
                      onChange={(e: any) => {
                        onChange(e.value);
                      }}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,
                        border: `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      {dataCities?.map((city) => {
                        return (
                          <Option value={city.nome} key={city.nome}>
                            {city.nome}
                          </Option>
                        );
                      })}
                    </StyledSelect>
                  )}
                />
              </div>

              {errors.address_city ? (
                <span className="error">Insira um estado valido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing={isEditing}>
              <FormLabel>
                <h2>País</h2>
              </FormLabel>

              <input
                defaultValue={data?.address_country}
                {...register("address_country")}
                disabled={!isEditing}
              />

              {errors.address_country ? (
                <span className="error">Insira um país válido</span>
              ) : null}
            </ContainerInput>
          </FormFull>
        </>
      ) : null}

      <ContainerDescription>
        <h4>Informações do Suporte</h4>
      </ContainerDescription>

      <FormFull>
        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Email de Suporte</h2>
          </FormLabel>

          <input
            defaultValue={data?.support_email}
            {...register("support_email")}
            disabled={!isEditing}
          />
        </ContainerInput>

        <ContainerInput isEditing={isEditing}>
          <FormLabel>
            <h2>Whatsapp de Suporte</h2>
          </FormLabel>

          <input
            defaultValue={data?.support_whatsapp ?? ""}
            {...register("support_whatsapp")}
            disabled={!isEditing}
          />
        </ContainerInput>
      </FormFull>

      <ContainerDescription>
        <h4>Check-out</h4>
      </ContainerDescription>

      <FormFullNormal>
        <ContainerInputFull hasPrefix={isEditing} isEditing={isEditing}>
          <FormLabel>
            <h2>Link do check-out</h2>
          </FormLabel>

          {isEditing ? (
            <div className="hasPrefix">
              <h3 className="prefix">
                {`${process.env.REACT_APP_EVENTX_WEBSITE}/`}
              </h3>

              <input {...register("nickname")} disabled={!isEditing} />
            </div>
          ) : null}

          {!isEditing ? (
            <input {...register("formattedLinkNick")} disabled={!isEditing} />
          ) : null}

          {errors.name ? (
            <span className="error">Insira um Nome válido</span>
          ) : null}
        </ContainerInputFull>
      </FormFullNormal>

      <ContainerDescription>
        <h4>Imagem de capa</h4>

        <p>
          Especificações: arquivo do tipo PNG ou JPEG, com dimensão recomendada
          de 1580x660 px e tamanho máximo de 4MB.
        </p>

        {data.photo ? (
          <Background img={data.photo}>
            {isEditing ? (
              <div className="row">
                <input
                  id="file"
                  type="file"
                  style={{ display: "none" }}
                  accept="image/png, image/gif, image/jpeg, image/webp, image/jfif"
                  onChange={onSelectFile}
                />

                <label className="badge" htmlFor="file">
                  <Circle>
                    {!loadingFile ? <img src={editIcon} alt="" /> : null}

                    {loadingFile ? (
                      <Spin
                        spinning={loadingFile}
                        indicator={antIconImageLoading}
                      />
                    ) : null}
                  </Circle>
                </label>
              </div>
            ) : null}
          </Background>
        ) : (
          <div className="empty">
            <h3>Não há foto desse evento.</h3>
            <MdOutlineNoPhotography size={94} color={Colors.black3} />
          </div>
        )}
      </ContainerDescription>

      <ContainerDescription>
        <h4>Status do administrador</h4>

        <FormFull>
          <ContainerInputFull isEditing={isEditing}>
            <FormLabel>
              <h2>Status do administrador</h2>
            </FormLabel>

            <div className="select">
              <Controller
                control={control}
                name="admin_status"
                rules={{ required: "Status do administrador obrigatório" }}
                defaultValue={data?.admin_status}
                render={({
                  field: { onChange, value },
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => (
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Escolha o status do evento"
                    value={value}
                    defaultValue={data?.admin_status}
                    disabled={!isEditing}
                    onChange={(e: any) => {
                      onChange(e.value);
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      outline: 0,
                      border: `2px solid ${Colors.secondary20}`,
                      borderRadius: "8px",
                      marginTop: "2px",
                    }}
                  >
                    {ADMIN_STATUS?.map((item) => {
                      return (
                        <Option value={item.value} key={item.value}>
                          {item.label}
                        </Option>
                      );
                    })}
                  </StyledSelect>
                )}
              />
            </div>

            {errors.admin_status ? (
              <span className="error">{errors.admin_status?.message}</span>
            ) : null}
          </ContainerInputFull>
        </FormFull>
      </ContainerDescription>

      {shouldRenderReasonField ? (
        <FormFull>
          <ContainerInputFull isEditing={isEditing}>
            <FormLabel>
              <h2>Motivo do status</h2>
            </FormLabel>

            <input
              defaultValue={data?.admin_status_detail}
              {...register("admin_status_detail")}
              disabled={!isEditing}
            />

            {errors.admin_status_detail ? (
              <span className="error">
                {errors?.admin_status_detail?.message}
              </span>
            ) : null}
          </ContainerInputFull>
        </FormFull>
      ) : null}

      <ContainerButtons>
        {isEditing ? (
          <ButtonRemove onClick={() => setIsEditing(false)}>
            Cancelar
          </ButtonRemove>
        ) : null}

        {isEditing ? (
          <ButttonEdit type="submit" isLoading={isSubmitting}>
            <Spin spinning={isSubmitting} indicator={antIcon} />

            <span>Salvar</span>
          </ButttonEdit>
        ) : null}

        {!isEditing ? (
          <ButttonEdit type="button" onClick={() => setIsEditing(true)}>
            Editar
          </ButttonEdit>
        ) : null}
      </ContainerButtons>
    </ContainerForm>
  );
}
