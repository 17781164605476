import styled from "styled-components";
import { Colors } from "constants/colors";
import { Select } from "antd";
import { Link } from "react-router-dom";

export const Container = styled.div`
  /* max-width: 900px; */
  width: 100%;
  height: 100%;
  /* z-index: 2; */
  /* z-index: -1; */
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const Card = styled.div`
  /* max-width: 900px; */
  width: 100%;
  height: 100%;
  /* min-height: 390px; */

  /* padding: 34px 34px; */
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  background: ${Colors.white};
  border: 1px solid ${Colors.border};
  border-radius: 8px;
  margin-top: 24px;

  @media (max-width: 768px) {
    display: none;
  }

  div.container-empty {
    width: 100%;
    height: 200px;

    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      color: ${Colors.secondary80};
      text-align: center;
    }
  }

  section {
    /* max-width: 600px; */

    width: 100%;
    position: relative;

    /* overflow-x: hidden; */

    .responsive {
      @media (max-width: 1000px) {
        display: none;
      }
    }

    table {
      /* width: 100%; */
      /* border: 1px solid black; */
      /* border-collapse: collapse; */
      /* width: 100%; */

      th,
      td {
        /* border: 1px solid black; */
        /* border-collapse: collapse; */
        /* background: red; */

        vertical-align: top;

        div.column {
          /* width: 8%; */
          /* background: red; */
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          word-break: break-all;

          strong {
            margin-top: 54px;
          }

          padding-right: 33px;
        }

        strong {
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 100%;
          /* identical to box height, or 14px */
          word-wrap: break-word;
          color: ${Colors.secondary80};
        }
      }

      tr.main {
        padding-top: 20px;
      }

      th,
      td,
      tr {
        /* width: 100%; */
        padding: 5px;
        padding-left: 30px;

        /* padding-right: 30px; */
      }
      th {
        text-align: left;
      }

      tr:nth-child(even) {
        width: 100%;
        background-color: ${Colors.gray70};
      }
    }

    table {
      /* width: 100%; */
      width: 100%;
      thead {
        background: ${Colors.secondary50};
        height: 37px;
        padding-left: 30px;

        tr {
          th {
            color: ${Colors.white};
          }
        }
      }

      tbody {
        /* width: 100%; */
        /* padding-left: 10px; */
        /* width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start; */

        tr {
          /* width: 100%; */
          /* height: 54px; */
          /*
          td {
            width: 16.6%;

          } */
        }
      }
    }
  }
`;

export const SidebarFilters = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 25px;

  div.card-filter {
    cursor: pointer;
    width: 192px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 28px;

    span {
      font-weight: normal;
      font-size: 12px;
      line-height: 100%;

      display: flex;
      align-items: center;

      color: ${Colors.secondary70};
    }

    span.active {
      font-weight: bold;
    }
  }

  div.active {
    background: ${Colors.gray80};
  }
`;

export const ContainerResults = styled.div`
  padding-left: 47px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CardResult = styled.div`
  width: 194px;
  height: 117px;
  display: flex;
  flex-direction: column;
  background: ${Colors.gray80};

  box-sizing: border-box;
  border-radius: 5px;
  /* padding: 12px 19px 0px 16px; */
`;

interface HeaderFilterProps {
  filterAdvancedIsActive: boolean;
}

export const HeaderFilter = styled.div<HeaderFilterProps>`
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: ${Colors.secondary80};
  padding-right: 28px;

  h6 {
    /* width: 40%; */
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: ${Colors.white};

    strong {
      font-weight: bold;
    }
  }

  div.first {
    /* max-width: 496px; */
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    height: 32px;

    nav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      background: ${Colors.white};

      /* width: 100%; */
      height: 32px;
      border: 1px solid ${Colors.border};
      border-radius: 5px 0px 0px 5px;
      margin-left: 32px;

      @media (max-width: 1100px) {
        width: 80%;
      }

      /* @media (max-width: 1180px) {
        display: none;
      } */

      div.dropdown {
        width: 166px;
        height: 32px;
        cursor: pointer;
        border-right: 1px solid ${Colors.border};
        display: flex;
        flex-direction: column;
        align-items: center;
        /* justify-content: center; */
        /* padding: 0px 2px 0px 12px; */
        z-index: 3;

        /* @media (max-width: 1300px) {
        width: 20%;
      } */

        @media (max-width: 1100px) {
          /* width: 20%; */
          /* svg {
          width: 20px;
          height: 20px;
        } */
        }

        div.icon {
          margin-top: 4px;
        }

        @media (max-width: 1300px) {
          align-items: center;
          justify-content: center;

          div.icon {
            display: none;
          }

          div.text {
            display: none;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;

          margin-top: 6px;
          color: ${Colors.secondary40};

          @media (max-width: 1304px) {
            font-size: 10px;
          }

          @media (max-width: 1100px) {
            font-size: 8px;
          }
        }
      }

      div.clear {
        width: 15px;
        display: ${(props) => (props.filterAdvancedIsActive ? "none" : "")};
      }

      div.searchInput {
        width: 301px;
        height: 100%;

        display: ${(props) => (props.filterAdvancedIsActive ? "none" : "")};

        input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;

          /* padding: 0px 14px 0px 0px; */
          padding-left: 11px;
          caret-color: ${Colors.primary100};

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;

          ::placeholder {
            color: ${Colors.gray};
          }

          color: ${Colors.gray};
          /* opacity: 0.5; */
        }
      }
    }

    div.search {
      cursor: pointer;

      display: ${(props) => (props.filterAdvancedIsActive ? "none" : "flex")};

      align-items: center;
      justify-content: center;
      width: 46px;
      border-radius: 0px 5px 5px 0px;
      height: 100%;
      /* border-left: 1px solid ${Colors.border}; */
      background: ${Colors.tertiary100};

      /* @media (max-width: 1300px) {
        width: 15%;
      } */
    }
  }
`;

export const AdvancedFilters = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 168px;
  background: ${Colors.secondary70};
  padding-left: 30px;
  padding-right: 30px;
  /* padding-bottom: 16px; */
`;

export const FirstRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const SecondRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 9px;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  strong {
    color: ${Colors.white};
    font-weight: 500;
    font-size: 14px;
  }

  div.range-pick {
    border: 0;
    background: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 206px;
    height: 38px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    padding-left: 18px;
    padding-right: 2px;

    @media (max-width: 503px) {
      width: 140px;
      padding-left: 8px;

      input {
        font-size: 10px;
      }
    }

    input {
      max-width: 78px;
      height: 100%;
      border: 0;
      background: transparent;
      outline: 0;

      @media (max-width: 503px) {
        width: 98%;
      }
    }
  }

  div.normal-input {
    display: flex;
    flex-direction: row;
    /* margin-bottom: 24px; */

    strong {
      color: ${Colors.white};
      font-weight: 500;
      font-size: 14px;
    }

    border: 0;
    background: ${Colors.white};
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 206px;
    height: 38px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    padding-left: 18px;
    padding-right: 2px;

    @media (max-width: 503px) {
      width: 140px;
      padding-left: 8px;

      input {
        font-size: 10px;
      }
    }

    input {
      width: 100%;
      height: 100%;
      border: 0;
      background: transparent;
      outline: 0;

      @media (max-width: 503px) {
        width: 98%;
      }
    }
  }

  input.normal {
    outline: none;
    background: ${Colors.white};
    border: 0;
    width: 206px;
    height: 38px;
    padding-left: 18px;
    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;

    @media (max-width: 503px) {
      width: 140px;
    }
  }

  div.select {
    border: 0;
    width: 206px;
    height: 38px;
    outline: none;
    background: ${Colors.white};

    margin-top: 8px;
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;

    @media (max-width: 503px) {
      width: 140px;
    }
  }
`;

export const StyledSelect = styled(Select)`
  /* .ant-select-selector {
    border-color: none !important;
    box-shadow: none !important;
    height: 38px;
  } */
`;

export const Drop = styled.div`
  min-width: 100%;

  height: 117px;
  background: ${Colors.secondary10};
`;

interface TrProps {
  active: boolean;
  hasAdminStatus: boolean;
}

export const TrBody = styled.tr<TrProps>`
  height: ${(props) => (props.active ? "80px" : "80px")};
  max-width: 900px;
  width: 100%;
  div.all {
    padding-top: 10px;
    word-break: break-all;
  }

  div.informations {
    display: flex;
    flex-direction: column;
    word-break: break-all;

    strong {
      margin-top: 10px;
    }

    strong.last {
      margin-top: 16px;
    }
  }

  background: ${(props) => (props.active ? Colors.secondary10 : "")} !important;
`;

export const Tr = styled.tr<TrProps>`
  /* max-width: 20px; */
  /* cursor: pointer; */

  height: ${(props) =>
    props.active
      ? props.hasAdminStatus
        ? "180px"
        : "127px"
      : props.hasAdminStatus
      ? "70px"
      : "54px"};
  max-width: 900px;
  width: 100%;
  /* display: flex;
  flex-direction: row;
  align-items: flex-start; */

  div.status-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  div.all {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;
    padding-top: 5px;
    word-break: break-all;

    > strong {
      font-size: 12px;
    }
  }

  background: ${(props) => (props.active ? Colors.secondary10 : "")};
`;

export const TrExtra = styled.tr`
  width: 100%;
  height: 100%;
  background: ${Colors.secondary10} !important;
  border-top: 1px solid ${Colors.secondary1};
  border-bottom: 1px solid ${Colors.secondary1};
  margin-top: 0px;

  th {
    font-weight: 700;
    font-size: 10px;
    line-height: 100%;
    text-transform: uppercase;

    color: ${Colors.border6};
  }
`;

export const Status = styled.div`
  width: 56px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${Colors.success100};
  border-radius: 50px;

  span {
    font-weight: bold;
    font-size: 10px;
    line-height: 160%;

    text-align: center;
    text-transform: uppercase;

    color: ${Colors.white};

    text-shadow: 0px 0px 8px rgba(113, 224, 131, 0.5);
    margin-bottom: 0px;
  }
`;

export const ContainerItemsMobile = styled.div`
  max-width: 1180px;
  width: 100%;
  height: 100%;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const CardMobile = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

interface HeaderFiltersMobileProps {
  filterAdvancedIsActive: boolean;
}

export const HeaderFiltersMobile = styled.div<HeaderFiltersMobileProps>`
  width: 100%;
  min-height: 24px;

  background: ${Colors.secondary80};
  border-radius: 5px 5px 0px 0px;
  padding-left: 16px;
  padding-right: 20px;

  div {
    width: 100%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    height: 32px;

    nav {
      width: 100%;
      height: 32px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      background: ${Colors.white};

      margin-top: 16px;

      border: 1px solid ${Colors.border};
      border-radius: 5px 0px 0px 5px;

      /* @media (max-width: 1180px) {
        display: none;
      } */

      div.dropdown {
        width: 40%;
        height: 32px;
        cursor: pointer;
        border-right: 1px solid ${Colors.border};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 2px 0px 12px;

        @media (max-width: 1300px) {
          width: 20%;
        }

        @media (max-width: 1100px) {
          width: 20%;
          svg {
            width: 20px;
            height: 20px;
          }
        }

        div.icon {
          margin-top: 4px;
        }

        @media (max-width: 1300px) {
          align-items: center;
          justify-content: center;

          div.icon {
            display: none;
          }

          div.text {
            display: none;
          }
        }

        h3 {
          font-weight: bold;
          font-size: 12px;
          line-height: 18px;

          margin-top: 6px;
          color: ${Colors.secondary40};

          @media (max-width: 1304px) {
            font-size: 10px;
          }

          @media (max-width: 1100px) {
            font-size: 8px;
          }
        }
      }

      div.searchInput {
        width: 100%;
        height: 100%;

        input {
          height: 100%;
          width: 100%;
          border: none;
          outline: none;

          /* padding: 0px 14px 0px 0px; */
          padding-left: 18px;
          caret-color: ${Colors.primary100};

          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;

          ::placeholder {
            color: ${Colors.gray};
          }

          color: ${Colors.gray};
          /* opacity: 0.5; */
        }
      }
    }

    div.search {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      margin-top: 16px;
      border-radius: 0px 5px 5px 0px;
      height: 100%;
      /* border-left: 1px solid ${Colors.border}; */
      background: ${Colors.tertiary100};
    }
  }

  div.select {
    height: 32px;
    width: 100%;
    background: ${Colors.white};
    border: 2px solid ${Colors.secondary20};
    border-radius: 5px;
    margin-top: 16px;
    /* padding-left: 18px;
  padding-right: 13px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h4 {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      /* identical to box height */

      color: ${Colors.secondary40};
      margin-bottom: 0px;
    }
  }

  div.footer-informations {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: ${(props) => (props.filterAdvancedIsActive ? "14px" : "29px")};

    p.quantity {
      font-weight: 400;
      font-size: 11px;

      /* line-height: 100%; */
      color: ${Colors.white};
    }
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Item = styled.div`
  min-height: 184px;
  width: 100%;
  margin-top: 24px;
  background: ${Colors.white};
  border: 0.6px solid ${Colors.secondary40};
  box-sizing: border-box;
  border-radius: 5px;
  padding: 18px 32px 27px 20px;

  display: flex;
  flex-direction: column;

  div.skeleton {
    margin-top: 8px;
  }

  h1 {
    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: ${Colors.secondary80};
    margin-top: 16px;
    margin-bottom: 0px;
  }

  h2 {
    margin-top: 8px;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    color: ${Colors.secondary80};
  }

  p {
    font-weight: 500;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    margin-top: 11px;
    color: ${Colors.gray90};
  }

  span.text {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    color: ${Colors.secondary80};
    margin-top: 8px;
    margin-bottom: 0px;
  }

  span.text-last {
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    color: ${Colors.secondary80};
    margin-top: 16px;
    margin-bottom: 0px;
  }

  div.chevron-mobile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 32px;
  }

  div.footer-details {
    border-top: 1px solid ${Colors.border60};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    margin-top: 24px;
    padding-top: 24px;
  }
`;

export const DropDownFilter = styled.div`
  display: block;
  /* position: absolute; */
  background: ${Colors.white};
  width: 179px;
  min-height: 123px;

  /* margin-left: 20px; */
  z-index: -1;
  margin-top: -15px;

  border-radius: 0px 0px 14px 14px;

  /* box-shadow: 0px 0px 30px rgba(18, 25, 34, 0.2); */
  border-radius: 0px 0px 14px 14px;

  ul {
    margin-top: 18px;

    h2 {
      color: ${Colors.secondary20};
      font-weight: bold;
      font-size: 16px;
      line-height: 160%;
      margin-left: 17px;
    }

    li {
      width: 100%;
      list-style: none;
      height: 43px;

      margin-top: 10px;
      border-bottom: 1px solid ${Colors.border10};
      display: flex;
      align-items: center;

      span {
        cursor: pointer;
        color: ${Colors.secondary100};
        font-weight: 600;
        font-size: 20px;
        line-height: 160%;
        margin-left: 17px;
      }
    }

    li.last {
      border: 0;
    }

    li.exit {
      border: 0;

      span {
        color: ${Colors.primary100};
      }
    }
  }
`;

export const LinkStyledMobile = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 20px;

  width: 97px;
  height: 28px;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 17px;
  background: transparent;

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    color: ${Colors.primary100};
  }
`;

export const LinkStyled = styled(Link)<{ hasAdminStatus: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  z-index: 4;

  margin-top: ${({ hasAdminStatus }) => (hasAdminStatus ? "120px" : "60px")};

  width: 97px;
  height: 28px;

  border: 1px solid ${Colors.primary100};
  box-sizing: border-box;
  border-radius: 17px;
  background: transparent;

  span {
    font-weight: 700;
    font-size: 12px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;

    color: ${Colors.primary100};
  }
`;
