import { useEffect, useState } from "react";

import {
  Container,
  ContainerItems,
  ListButtons,
  ContainerItemsTable,
  ButtonBorder,
  ContainerTabs,
  AllEvents,
  ContainerEvents,
  ListEvents,
  ContainerFormDetailsPurchase,
  ContainerNormalButtons,
  ButtonCancel,
  ContainerModalAddDocument,
  ButtonCancelPurchase,
  Ticket,
} from "./styles";
import { Sidebar } from "components/Sidebar";

import { Spin, Tabs } from "antd";

import edit_purchase from "assets/edit-purchase-icon.svg";

import { LoadingOutlined } from "@ant-design/icons";

import ticketImage from "assets/ticket.svg";
import points from "assets/points.svg";

import { Wrapper } from "./styles";
import { Header } from "components/Header";

import { Colors } from "constants/colors";

import { PurchaseType, TicketPurchase, UserTicket } from "types";

import { authClient } from "services/authClient";
import { useAuthSale } from "services/hooks/sale";
import { Modal } from "components/Modal";

import { StatusComponent } from "components/Status";
import { DetailsPurchase } from "./components/DetailsPurchase";

import resendIcon from "assets/resendIcon.svg";
import cancelIcon from "assets/cancelIcon.svg";

import eyes_secondary from "assets/eyes-secondary.svg";

import {
  Dropdown,
  DropDownContainer,
  LiStyled,
  LiStyledLast,
} from "styles/Dropdown";

import { GoChevronRight } from "react-icons/go";
import { useNavigate, useParams } from "react-router-dom";
import { EmptyDetail } from "components/EmptyDetail";

import { ModalReversePurchase } from "./components/ModalReversePurchase";
import { LinkWithHover } from "styles/LinkWithHover";

import { useDisclosure } from "hooks/useDisclosure";
import { ModalEditPurchaseUser } from "./components/ModalEditPurchaseTicket";
import { ModalTicket } from "components/ModalTicket";

const { TabPane } = Tabs;

export function SalePage() {
  const [activeTicket, setActiveTicket] = useState<UserTicket | null>(null);

  const navigate = useNavigate();

  const { isModalVisible, showModal, handleOk, handleCancel } = useDisclosure();

  const { id } = useParams();

  const { data } = useAuthSale<PurchaseType>({ saleId: String(id) });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [isModalAddDocumentVisible, setIsModalAddDocumentVisible] =
    useState(false);

  const showModalAddDocument = () => {
    setIsModalAddDocumentVisible(true);
  };

  const handleAddDocumentOk = () => {};

  const handleAddDocumentCancel = () => {
    setIsModalAddDocumentVisible(false);
  };

  const [isModalResendPurchaseVisible, setIsModalResendPurchaseVisible] =
    useState(false);

  const showModalResendPurchase = () => {
    setIsModalResendPurchaseVisible(true);
  };

  const handleResendPurchaseOk = () => {};

  const handleResendPurchaseCancel = () => {
    setIsModalResendPurchaseVisible(false);
  };

  const [isModalResendTicketVisible, setIsModalResendTicketVisible] =
    useState(false);

  const handleResendTicketOk = () => {};

  const handleResendTicketCancel = () => {
    setIsModalResendTicketVisible(false);
  };

  const [
    isModalVisualizatingTicketVisible,
    setIsModalVisualizatingTicketVisible,
  ] = useState(false);

  const showModalVisualizatingTicket = () => {
    setIsModalVisualizatingTicketVisible(true);
  };

  const handleVisualizatingTicketOk = () => {
    setActiveTicket(null);
  };

  const handleVisualizatingTicketCancel = () => {
    setIsModalVisualizatingTicketVisible(false);
    setActiveTicket(null);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const [loadingResend, setLoadingResend] = useState(false);

  const handleResendPurchase = async () => {
    try {
      setLoadingResend(true);
      await authClient.patch(`/backoffice/resend-purchase/${data?._id}`);

      setLoadingResend(false);

      handleResendPurchaseCancel();

      alert("Ingressos reenviados com sucesso");
    } catch (err) {
      setLoadingResend(false);
      alert("Erro ao reenviar os ingressos");
    }
  };

  const [loadingResendTicket, setLoadingResendTicket] = useState(false);

  const handleResendTicket = async () => {
    try {
      setLoadingResendTicket(true);
      await authClient.patch(`/backoffice/resend-ticket/${activeTicket?._id}`);

      setLoadingResendTicket(false);

      handleResendTicketCancel();

      alert("Ingresso reenviado com sucesso");
    } catch (err) {
      setLoadingResend(false);
      alert("Erro ao reenviar o ingresso");
    }
  };

  function handleSelectedTicket(ticket: UserTicket) {
    if (activeTicket?._id === ticket._id) {
      setActiveTicket(null);

      return;
    }

    setActiveTicket(ticket);
  }

  return (
    <Wrapper>
      <Sidebar />

      <Container>
        <Header />
        <ContainerItems>
          <div className="header">
            <h1>Vendas</h1>
            <GoChevronRight
              className="icon"
              size={28}
              color={Colors.secondary80}
            />

            <h1>Detalhes</h1>
          </div>
          <h2 className="box">Caixa de ferramentas</h2>

          <ListButtons>
            <div className="last">
              <ButtonBorder onClick={() => navigate(-1)}>
                <span>Voltar</span>
              </ButtonBorder>
            </div>
          </ListButtons>
        </ContainerItems>

        <ContainerItemsTable>
          <ContainerTabs>
            <h3 className="title">Vendas</h3>

            {!data ? <EmptyDetail /> : null}

            {data ? (
              <Tabs defaultActiveKey="1" type="card" size={"small"}>
                <TabPane tab="Detalhes" key="1">
                  <ContainerFormDetailsPurchase>
                    <DetailsPurchase activePurchase={data} />

                    {data?.purchase_status === "PAID" ? (
                      <div className="buttons">
                        <button
                          className="resend-tickets"
                          onClick={showModalResendPurchase}
                        >
                          <img src={resendIcon} alt="resend" />

                          <span>Reenviar ingressos</span>
                        </button>

                        {data.payment_type !== "courtesy" ? (
                          <button
                            className="cancel-purchase"
                            onClick={showModalAddDocument}
                          >
                            <img src={cancelIcon} alt="cancel" />
                            <span> Cancelar Venda</span>
                          </button>
                        ) : null}
                      </div>
                    ) : null}
                  </ContainerFormDetailsPurchase>
                </TabPane>
                <TabPane tab="Ingressos" key="2">
                  <AllEvents>
                    <ContainerEvents>
                      <ListEvents>
                        {data?.user_tickets && data?.user_tickets.length > 0 ? (
                          data.user_tickets.map((ticket) => (
                            <Ticket>
                              <div className="first">
                                <img src={ticketImage} alt="event" />
                              </div>

                              <div className="column">
                                <head>
                                  <div>
                                    <LinkWithHover to={`/users/${ticket?._id}`}>
                                      <h3>
                                        {ticket.holder_name
                                          ? ticket.holder_name.length > 14
                                            ? `${ticket.holder_name.substring(
                                                0,
                                                14
                                              )}...`
                                            : ticket.holder_name
                                          : ""}
                                      </h3>
                                    </LinkWithHover>
                                  </div>

                                  <div className="options">
                                    <StatusComponent
                                      color={ticket.status ? "green" : "grey"}
                                      content={
                                        ticket.status ? "ATIVO" : "INATIVO"
                                      }
                                    />

                                    <DropDownContainer>
                                      <div
                                        className="area"
                                        onClick={() =>
                                          handleSelectedTicket(ticket)
                                        }
                                      >
                                        <img src={points} alt="" />
                                      </div>

                                      {activeTicket?._id === ticket._id ? (
                                        <Dropdown height="103px">
                                          <ul>
                                            <LiStyled
                                              onClick={
                                                showModalVisualizatingTicket
                                              }
                                            >
                                              <img
                                                src={eyes_secondary}
                                                alt="eye"
                                              />
                                              <h5>Visualizar ingresso</h5>
                                            </LiStyled>

                                            <LiStyledLast onClick={showModal}>
                                              <img
                                                src={edit_purchase}
                                                alt="eye"
                                              />
                                              <h5>Editar dados do comprador</h5>
                                            </LiStyledLast>
                                          </ul>
                                        </Dropdown>
                                      ) : null}
                                    </DropDownContainer>
                                  </div>
                                </head>

                                <div className="content">
                                  <h4>
                                    <strong>Email do titular:</strong>{" "}
                                    {ticket.holder_email
                                      ? ticket.holder_email
                                      : "Não informado"}
                                  </h4>
                                  <h4>
                                    <strong>Cpf do titular:</strong>{" "}
                                    {ticket.holder_cpf
                                      ? ticket.holder_cpf
                                      : "Não informado"}
                                  </h4>

                                  <LinkWithHover
                                    to={
                                      ticket._id
                                        ? `/tickets/${ticket?._id}`
                                        : ""
                                    }
                                  >
                                    {/* <h4>
                                      <strong>Nome do Ingresso:</strong>{" "}
                                      {ticket._id ? ticket?.name : ""}
                                    </h4> */}
                                  </LinkWithHover>

                                  <LinkWithHover
                                    to={`/events/${data?.event?.id}`}
                                  >
                                    <h4>
                                      <strong>Nome do Evento:</strong>{" "}
                                      {data.event ? data?.event?.name : ""}
                                    </h4>
                                  </LinkWithHover>
                                </div>
                              </div>
                            </Ticket>
                          ))
                        ) : (
                          <div>Não ha Ingressos dessa venda</div>
                        )}
                      </ListEvents>
                    </ContainerEvents>
                  </AllEvents>
                </TabPane>
              </Tabs>
            ) : null}
          </ContainerTabs>
        </ContainerItemsTable>

        {data ? (
          <ModalReversePurchase
            handleAddDocumentCancel={handleAddDocumentCancel}
            handleAddDocumentOk={handleAddDocumentOk}
            isModalAddDocumentVisible={isModalAddDocumentVisible}
            purchaseId={id ?? ""}
            dataPurchase={data}
          />
        ) : null}

        <ModalTicket
          isModalVisible={isModalVisualizatingTicketVisible}
          handleCancel={handleVisualizatingTicketCancel}
          handleOk={handleVisualizatingTicketOk}
          data={activeTicket ?? null}
        />

        <Modal
          isModalVisible={isModalResendPurchaseVisible}
          handleCancel={handleResendPurchaseCancel}
          handleOk={handleResendPurchaseOk}
        >
          <ContainerModalAddDocument>
            <h2 className="title">Reenviar Ingressos</h2>

            <p className="tickets">Voce deseja reenviar os ingressos ?</p>

            <ContainerNormalButtons>
              <ButtonCancel type="button" onClick={handleResendPurchaseCancel}>
                <span>Cancelar</span>
              </ButtonCancel>

              <ButtonCancelPurchase
                type="button"
                onClick={handleResendPurchase}
                isLoading={loadingResend}
                active
              >
                <Spin spinning={loadingResend} indicator={antIcon} />

                <span>Confirmar</span>
              </ButtonCancelPurchase>
            </ContainerNormalButtons>
          </ContainerModalAddDocument>
        </Modal>

        {activeTicket ? (
          <ModalEditPurchaseUser
            isModalVisible={isModalVisible}
            handleCancel={handleCancel}
            handleOk={handleOk}
            ticket={activeTicket}
            setActiveTicket={setActiveTicket}
          />
        ) : null}

        <Modal
          isModalVisible={isModalResendTicketVisible}
          handleCancel={handleResendTicketCancel}
          handleOk={handleResendTicketOk}
        >
          <ContainerModalAddDocument>
            <h2 className="title">Reenviar Ingressos</h2>

            <p className="tickets">Voce deseja reenviar o ingresso ?</p>

            <ContainerNormalButtons>
              <ButtonCancel type="button" onClick={handleResendTicketCancel}>
                <span>Cancelar</span>
              </ButtonCancel>

              <ButtonCancelPurchase
                type="button"
                onClick={handleResendTicket}
                isLoading={loadingResendTicket}
              >
                <Spin spinning={loadingResendTicket} indicator={antIcon} />

                <span>Confirmar</span>
              </ButtonCancelPurchase>
            </ContainerNormalButtons>
          </ContainerModalAddDocument>
        </Modal>
      </Container>
    </Wrapper>
  );
}
