import { Modal } from "components/Modal";
import {
  ButtonCancel,
  ButtonCancelPurchase,
  Center,
  ContainerInformations,
  ContainerInput,
  ContainerInputFull,
  ContainerModalAddDocument,
  ContainerNormalButtons,
  FormFull,
  FormFullFirst,
  FormLabel,
  StyledSelect,
} from "./styles";

import { SubmitHandler, useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";

import { LoadingOutlined } from "@ant-design/icons";

import checkCancelPurchaseIcon from "assets/checkCancelPurchaseIcon.svg";

import { Radio, Select, Spin } from "antd";
import { useCallback, useState } from "react";
import { Colors } from "constants/colors";
import { RiPencilFill } from "react-icons/ri";
import { cnpj, cpf } from "utils/masks";
import { useSWRConfig } from "swr";
import Datas from "constants/Datas";
import { PurchaseType } from "types";
import { CancelPurchase } from "services/hooks/sale";
const { Option } = Select;

interface DocumentFormData {
  refund_type: number;
  holder_name: string;
  bank_code: string;
  routing_number: string;
  account_number: string;
  document: string;
  type_document: number;
  type_account: number;
  digit: string;
}

const documentFormSchema = yup.object().shape({
  refund_type: yup.number().notRequired(),
  // holder_name: yup.string().required(),
  // bank_code: yup.string().when("refund_type", {
  //   is: 3,
  //   then: yup.string().required("Must"),
  // }),
  bank_code: yup.string().required(),
  routing_number: yup.string().required(),
  account_number: yup.string().required(),
  document: yup.string().required(),
  type_document: yup.number().notRequired(),
  type_account: yup.number().required(),
  digit: yup.string().notRequired(),
});

interface ModalReversePurchaseProps {
  isModalAddDocumentVisible: boolean;
  handleAddDocumentCancel(): void;
  handleAddDocumentOk(): void;
  purchaseId: string;
  dataPurchase: PurchaseType;
}

export function ModalReversePurchase({
  isModalAddDocumentVisible,
  handleAddDocumentCancel,
  handleAddDocumentOk,
  purchaseId,
  dataPurchase,
}: ModalReversePurchaseProps) {
  const [value, setValue] = useState(1);

  const [stepCancel, setStepCancel] = useState(0);
  const [accountSelected, setAccountSelected] = useState<
    null | string | number
  >(null);

  const { mutate } = useSWRConfig();

  const [refoundType, setRefoundType] = useState<number | null>(null);

  const {
    register: register3,
    handleSubmit: handleSubmit3,
    control: control3,
    formState: { errors: errors3, isSubmitting: isSubmitting3 },
  } = useForm<DocumentFormData>({
    resolver: yupResolver(documentFormSchema),
  });

  const handleKeyUpCpf = useCallback((e: React.FormEvent<HTMLInputElement>) => {
    cpf(e);
  }, []);

  const handleKeyUpCnpj = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      cnpj(e);
    },
    []
  );

  const handleChangeStepPurchase = (index: number) => {
    setStepCancel(index);
  };

  const onChangeRadio = (e: any) => {
    setValue(e.target.value);
  };

  const antIcon = (
    <LoadingOutlined style={{ fontSize: 24, color: Colors.white }} spin />
  );

  const [completeCancel, setCompleteCancel] = useState(false);
  const [loadingCancelPurchase, setLoadingCancelPurchase] = useState(false);

  const handleCancelPurchaseByCredit = async () => {
    try {
      setLoadingCancelPurchase(true);

      const credentials = {
        refund_type: refoundType,
      };

      await CancelPurchase({ purchaseId, credentials });

      mutate(`/backoffice/purchases?id=${purchaseId}`);

      setLoadingCancelPurchase(false);
      setCompleteCancel(true);
    } catch (err) {
      setLoadingCancelPurchase(false);
      alert(`Erro em estornar a compra`);
    }
  };

  const handleResetAndOk = () => {
    handleAddDocumentCancel();
    setCompleteCancel(false);
    setStepCancel(0);
    setAccountSelected(null);
  };

  const handleCancelPurchase: SubmitHandler<DocumentFormData> = async ({
    refund_type,
    holder_name,
    type_document,
    bank_code,
    routing_number,
    account_number,
    document,
    type_account,
    digit,
  }) => {
    try {
      setLoadingCancelPurchase(true);

      const credentials = {
        refund_type: 3,
        account_information: {
          // holder_name: "Teste",
          bank_code,
          routing_number,
          account_number: account_number + digit,
          type_document: value,
          document,
          type_account,
        },
      };

      await CancelPurchase({ purchaseId, credentials });

      mutate(`/backoffice/purchases?id=${purchaseId}`);

      setLoadingCancelPurchase(false);
      setCompleteCancel(true);
    } catch {
      setLoadingCancelPurchase(false);
      alert(`Erro em estornar a compra`);
    }
  };

  return (
    <Modal
      isModalVisible={isModalAddDocumentVisible}
      handleCancel={handleAddDocumentCancel}
      handleOk={handleAddDocumentOk}
    >
      {accountSelected !== "another" &&
      accountSelected !== "credit" &&
      refoundType !== 3 &&
      !completeCancel ? (
        <ContainerModalAddDocument>
          <h2 className="title">Cancelar Venda</h2>

          <p className="description">
            Selecione um dos meios de reembolso para realizar o estorno do
            valor.
          </p>

          {stepCancel >= 0 ? (
            <FormFull>
              <ContainerInput isEditing={true}>
                <FormLabel>
                  <h2>Tipo do reembolso</h2>
                </FormLabel>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Selecione uma opção"
                    value={refoundType ?? "Selecione uma opção"}
                    onChange={(e: any) => {
                      setRefoundType(e.value);
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      outline: 0,
                      border: `2px solid ${Colors.secondary20}`,
                      borderRadius: "8px",
                      marginTop: "2px",
                    }}
                  >
                    {dataPurchase?.payment_type &&
                    dataPurchase?.payment_type === "credit-card" ? (
                      <Option value={1} key={1}>
                        Estorno no cartão de crédito
                      </Option>
                    ) : null}

                    {dataPurchase?.payment_type &&
                    dataPurchase?.payment_type === "credit-card" ? (
                      <Option value={2} key={2}>
                        Crédito na conta
                      </Option>
                    ) : null}

                    {dataPurchase?.payment_type &&
                    dataPurchase?.payment_type === "boleto" ? (
                      <Option value={3} key={3}>
                        Boleto bancário
                      </Option>
                    ) : null}
                  </StyledSelect>
                </div>

                {errors3.refund_type ? (
                  <span className="error">Insira uma reembolso valido</span>
                ) : null}
              </ContainerInput>
            </FormFull>
          ) : null}

          {refoundType !== null && refoundType === 2 ? (
            <FormFull>
              <ContainerInput isEditing={true}>
                <FormLabel>
                  <h2>Conta bancária</h2>
                </FormLabel>

                <div className="select">
                  <StyledSelect
                    bordered={false}
                    labelInValue
                    placeholder="Selecione uma opção"
                    value={accountSelected ?? "Selecione uma opção"}
                    onChange={(e: any) => {
                      setAccountSelected(e.value);
                    }}
                    style={{
                      width: "100%",
                      minHeight: "100%",
                      outline: 0,
                      border: `2px solid ${Colors.secondary20}`,
                      borderRadius: "8px",
                      marginTop: "2px",
                    }}
                  >
                    {/* <Option value={1} key={1}>
                      Banco Safra S.A. | CC: 00005432-1
                    </Option>

                    <Option value={2} key={2}>
                      Itaú Unibanco S.A. | CC: 00005432-1
                    </Option>

                    <Option value={3} key={3}>
                      Banco Bradescard S.A. | CC: 00005432-1
                    </Option> */}

                    <Option value={"another"} key={"another"}>
                      <div
                        className="row"
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <RiPencilFill color={Colors.success100} />{" "}
                        <h4 style={{ marginBottom: 0, marginLeft: 10 }}>
                          Outra Conta
                        </h4>
                      </div>
                    </Option>
                  </StyledSelect>
                </div>

                {errors3.type_document ? (
                  <span className="error">Insira um tipo valido</span>
                ) : null}
              </ContainerInput>
            </FormFull>
          ) : null}

          {stepCancel === 0 && refoundType === null ? (
            <ContainerNormalButtons>
              <ButtonCancel type="button" onClick={handleAddDocumentCancel}>
                <span>Cancelar</span>
              </ButtonCancel>

              <ButtonCancelPurchase
                disabled={refoundType === null}
                active={refoundType !== null}
                type="button"
                onClick={() => handleChangeStepPurchase(1)}
              >
                <span>Prosseguir</span>
              </ButtonCancelPurchase>
            </ContainerNormalButtons>
          ) : null}

          {stepCancel === 0 && refoundType !== null && refoundType === 1 ? (
            <ContainerNormalButtons>
              <ButtonCancel type="button" onClick={handleAddDocumentCancel}>
                <span>Cancelar</span>
              </ButtonCancel>

              <ButtonCancelPurchase
                active
                type="button"
                onClick={() => setAccountSelected("credit")}
              >
                <span>Prosseguir</span>
              </ButtonCancelPurchase>
            </ContainerNormalButtons>
          ) : null}

          {stepCancel === 0 && refoundType === 2 ? (
            <ContainerNormalButtons>
              <ButtonCancel type="button" onClick={() => setRefoundType(null)}>
                <span>Voltar</span>
              </ButtonCancel>

              <ButtonCancelPurchase
                disabled
                active={false}
                type="button"
                onClick={() => handleChangeStepPurchase(1)}
              >
                <span>Prosseguir</span>
              </ButtonCancelPurchase>
            </ContainerNormalButtons>
          ) : null}

          {stepCancel > 0 ? (
            <ContainerNormalButtons>
              <ButtonCancel
                type="button"
                onClick={() => handleChangeStepPurchase(0)}
              >
                <span>Voltar</span>
              </ButtonCancel>

              <ButtonCancelPurchase type="button" onClick={() => {}}>
                <Spin spinning={isSubmitting3} indicator={antIcon} />

                <span>Confirmar</span>
              </ButtonCancelPurchase>
            </ContainerNormalButtons>
          ) : null}
        </ContainerModalAddDocument>
      ) : null}

      {accountSelected && accountSelected === "credit" && !completeCancel ? (
        <ContainerModalAddDocument
          onSubmit={handleSubmit3(handleCancelPurchase)}
        >
          <h2 className="title">Cancelar Venda</h2>

          <p className="description">
            Confirme as informações para o estorno do valor:
          </p>

          <ContainerInformations>
            <div className="inside">
              <header>
                <h3>{dataPurchase?.event?.name}</h3>
              </header>

              <main>
                <div className="row">
                  <div className="column">
                    <h2>VALOR DA COMPRA</h2>

                    <p>
                      1X {dataPurchase?.formattedTotal} (-
                      {dataPurchase.formattedTotalFee} Taxa Eventx)
                    </p>
                  </div>

                  <div className="column">
                    <h2>ID</h2>

                    <p>{dataPurchase?._id}</p>
                  </div>
                </div>

                <div className="row">
                  <div className="column">
                    <h2>VALOR DO ESTORNO</h2>

                    <p>1X {dataPurchase.formattedTotalWet}</p>
                  </div>

                  {/* <div className="column">
                    <h2>MÉTODO DE PAGAMENTO</h2>

                    <p>Crédito Mastercard **** **** **** 2454</p>
                  </div> */}
                </div>
              </main>
            </div>
          </ContainerInformations>

          <ContainerNormalButtons>
            <ButtonCancel
              type="button"
              onClick={() => setAccountSelected(null)}
            >
              <span>Voltar</span>
            </ButtonCancel>

            <ButtonCancelPurchase
              active
              type="button"
              onClick={handleCancelPurchaseByCredit}
              isLoading={loadingCancelPurchase}
            >
              <Spin spinning={loadingCancelPurchase} indicator={antIcon} />

              <span>Confirmar</span>
            </ButtonCancelPurchase>
          </ContainerNormalButtons>
        </ContainerModalAddDocument>
      ) : null}

      {accountSelected && accountSelected === "another" && !completeCancel ? (
        <ContainerModalAddDocument
          onSubmit={handleSubmit3(handleCancelPurchase)}
        >
          <h2 className="title">Cancelar Venda</h2>

          <p className="description">
            Preencha abaixo os dados da conta bancária onde o estorno será
            realizado.
          </p>

          <h3 className="account">Informações da conta</h3>
          <FormFullFirst>
            <ContainerInput isEditing={true}>
              <FormLabel>
                <h2>Banco</h2>
              </FormLabel>

              <div className="select" style={{ marginBottom: 20 }}>
                <Controller
                  control={control3}
                  name="bank_code"
                  rules={{ required: "Salutation is required" }}
                  // disabled={isSubmitting}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Selecione uma opção"
                      // disabled={!isEditing}
                      // onChange={handleChangeType}
                      value={value ?? "Selecione uma opção"}
                      onChange={(e: any) => {
                        onChange(e.value);
                        //  setBankSelected (e);
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        position: "relative",
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,

                        border: errors3.bank_code
                          ? `2px solid ${Colors.red10}`
                          : `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      {Datas.Banks.map((bank) => (
                        <Option value={bank.value} key={bank.value}>
                          {`${bank.value} - ${bank.label}`}
                        </Option>
                      ))}
                    </StyledSelect>
                  )}
                />
              </div>

              {/* {errors3.bank_code ? (
                <span className="error">Insira um banco valido</span>
              ) : null} */}
            </ContainerInput>
          </FormFullFirst>

          <FormFullFirst>
            <ContainerInputFull>
              <FormLabel>
                <h2>Agência</h2>
              </FormLabel>

              <input {...register3("routing_number")} />

              {errors3.routing_number ? (
                <span className="error">Insira uma agência válida</span>
              ) : null}
            </ContainerInputFull>
          </FormFullFirst>

          <FormFullFirst>
            <ContainerInput>
              <FormLabel>
                <h2>Conta</h2>
              </FormLabel>

              <input {...register3("account_number")} />

              {errors3.account_number ? (
                <span className="error">Insira um numero válido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing>
              <FormLabel>
                <h2>Dígito</h2>
              </FormLabel>

              <input {...register3("digit")} />

              {/* {errors3.digit ? (
                <span className="error">Insira um codigo válido</span>
              ) : null} */}
            </ContainerInput>
          </FormFullFirst>

          {/* <FormFullFirst>
            <ContainerInput>
              <FormLabel>
                <h2>Nome do titular</h2>
              </FormLabel>

              <input {...register3("holder_name")} />

              {errors3.holder_name ? (
                <span className="error">Insira um nome válido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing>
              <FormLabel>
                <h2>Código bancário</h2>
              </FormLabel>

              <input {...register3("bank_code")} />

              {errors3.bank_code ? (
                <span className="error">Insira um codigo válido</span>
              ) : null}
            </ContainerInput>
          </FormFullFirst> */}

          {/* <FormFull>
            <ContainerInput>
              <FormLabel>
                <h2>Número de roteamento</h2>
              </FormLabel>

              <input {...register3("routing_number")} />

              {errors3.routing_number ? (
                <span className="error">Insira um numero válido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing>
              <FormLabel>
                <h2>Número da conta</h2>
              </FormLabel>

              <input {...register3("account_number")} />

              {errors3.account_number ? (
                <span className="error">Insira uma numero válido</span>
              ) : null}
            </ContainerInput>
          </FormFull> */}

          <FormFullFirst>
            <ContainerInput isEditing={true}>
              <FormLabel>
                <h2>Tipo de conta</h2>
              </FormLabel>

              <div className="select">
                <Controller
                  control={control3}
                  name="type_account"
                  rules={{ required: "Salutation is required" }}
                  // disabled={isSubmitting}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o tipo da conta"
                      // disabled={!isEditing}
                      // onChange={handleChangeType}
                      value={value}
                      onChange={(e: any) => {
                        onChange(e.value);
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,
                        border: `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      <Option value={1} key={1}>
                        Organizador
                      </Option>

                      <Option value={2} key={2}>
                        Usuário
                      </Option>
                    </StyledSelect>
                  )}
                />
              </div>

              {errors3.type_document ? (
                <span className="error">Insira um tipo valido</span>
              ) : null}
            </ContainerInput>
          </FormFullFirst>

          <FormFull>
            <ContainerInput isEditing={true}>
              <FormLabel>
                <h2>Tipo do documento</h2>
              </FormLabel>

              <div className="radio">
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Radio value={1}>CPF</Radio>
                  <Radio value={2}>CNPJ</Radio>
                </Radio.Group>
              </div>

              {/* {errors3.type_document ? (
                        <span className="error">Insira um tipo valido</span>
                      ) : null} */}
            </ContainerInput>
          </FormFull>

          <FormFull>
            <ContainerInput>
              <FormLabel>
                {value === 1 ? <h2>CPF</h2> : null}

                {value === 2 ? <h2>CNPJ</h2> : null}
              </FormLabel>

              {value === 1 ? (
                <input {...register3("document")} onKeyUp={handleKeyUpCpf} />
              ) : null}

              {value === 2 ? (
                <input {...register3("document")} onKeyUp={handleKeyUpCnpj} />
              ) : null}

              {errors3.document ? (
                <span className="error">Insira um documento válido</span>
              ) : null}
            </ContainerInput>
          </FormFull>

          <ContainerNormalButtons>
            <ButtonCancel
              type="button"
              onClick={() => setAccountSelected(null)}
            >
              <span>Voltar</span>
            </ButtonCancel>

            <ButtonCancelPurchase
              active
              type="submit"
              isLoading={isSubmitting3}
            >
              <Spin spinning={isSubmitting3} indicator={antIcon} />

              <span>Confirmar</span>
            </ButtonCancelPurchase>
          </ContainerNormalButtons>
        </ContainerModalAddDocument>
      ) : null}

      {refoundType === 3 && !completeCancel ? (
        <ContainerModalAddDocument
          onSubmit={handleSubmit3(handleCancelPurchase)}
        >
          <h2 className="title">Cancelar Venda</h2>

          <p className="description">
            Preencha abaixo os dados da conta bancária onde o estorno será
            realizado.
          </p>

          <h3 className="account">Informações da conta</h3>
          <FormFullFirst>
            <ContainerInput isEditing={true}>
              <FormLabel>
                <h2>Banco</h2>
              </FormLabel>

              <div className="select" style={{ marginBottom: 20 }}>
                <Controller
                  control={control3}
                  name="bank_code"
                  rules={{ required: "Salutation is required" }}
                  // disabled={isSubmitting}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Selecione uma opção"
                      // disabled={!isEditing}
                      // onChange={handleChangeType}
                      value={value ?? "Selecione uma opção"}
                      onChange={(e: any) => {
                        onChange(e.value);
                        //  setBankSelected (e);
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        position: "relative",
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,

                        border: errors3.bank_code
                          ? `2px solid ${Colors.red10}`
                          : `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      {Datas.Banks.map((bank) => (
                        <Option value={bank.value} key={bank.value}>
                          {`${bank.value} - ${bank.label}`}
                        </Option>
                      ))}
                    </StyledSelect>
                  )}
                />
              </div>

              {/* {errors3.bank_code ? (
                <span className="error">Insira um banco valido</span>
              ) : null} */}
            </ContainerInput>
          </FormFullFirst>

          <FormFullFirst>
            <ContainerInputFull>
              <FormLabel>
                <h2>Agência</h2>
              </FormLabel>

              <input {...register3("routing_number")} />

              {errors3.routing_number ? (
                <span className="error">Insira uma agência válida</span>
              ) : null}
            </ContainerInputFull>
          </FormFullFirst>

          <FormFullFirst>
            <ContainerInput>
              <FormLabel>
                <h2>Conta</h2>
              </FormLabel>

              <input {...register3("account_number")} />

              {errors3.account_number ? (
                <span className="error">Insira um numero válido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing>
              <FormLabel>
                <h2>Dígito</h2>
              </FormLabel>

              <input {...register3("digit")} />

              {errors3.digit ? (
                <span className="error">Insira um codigo válido</span>
              ) : null}
            </ContainerInput>
          </FormFullFirst>

          {/* <FormFullFirst>
            <ContainerInput>
              <FormLabel>
                <h2>Nome do titular</h2>
              </FormLabel>

              <input {...register3("holder_name")} />

              {errors3.holder_name ? (
                <span className="error">Insira um nome válido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing>
              <FormLabel>
                <h2>Código bancário</h2>
              </FormLabel>

              <input {...register3("bank_code")} />

              {errors3.bank_code ? (
                <span className="error">Insira um codigo válido</span>
              ) : null}
            </ContainerInput>
          </FormFullFirst> */}

          {/* <FormFull>
            <ContainerInput>
              <FormLabel>
                <h2>Número de roteamento</h2>
              </FormLabel>

              <input {...register3("routing_number")} />

              {errors3.routing_number ? (
                <span className="error">Insira um numero válido</span>
              ) : null}
            </ContainerInput>

            <ContainerInput isEditing>
              <FormLabel>
                <h2>Número da conta</h2>
              </FormLabel>

              <input {...register3("account_number")} />

              {errors3.account_number ? (
                <span className="error">Insira uma numero válido</span>
              ) : null}
            </ContainerInput>
          </FormFull> */}

          <FormFullFirst>
            <ContainerInput isEditing={true}>
              <FormLabel>
                <h2>Tipo de conta</h2>
              </FormLabel>

              <div className="select">
                <Controller
                  control={control3}
                  name="type_account"
                  rules={{ required: "Salutation is required" }}
                  // disabled={isSubmitting}
                  render={({
                    field: { onChange, onBlur, value, name, ref },
                    fieldState: { invalid, isTouched, isDirty, error },
                    formState,
                  }) => (
                    <StyledSelect
                      bordered={false}
                      labelInValue
                      placeholder="Escolha o tipo da conta"
                      // disabled={!isEditing}
                      // onChange={handleChangeType}
                      value={value}
                      onChange={(e: any) => {
                        onChange(e.value);
                      }}
                      getPopupContainer={(trigger) => trigger.parentNode}
                      style={{
                        width: "100%",
                        minHeight: "100%",
                        outline: 0,
                        border: `2px solid ${Colors.secondary20}`,
                        borderRadius: "8px",
                        marginTop: "2px",
                      }}
                    >
                      <Option value={1} key={1}>
                        Organizador
                      </Option>

                      <Option value={2} key={2}>
                        Usuário
                      </Option>
                    </StyledSelect>
                  )}
                />
              </div>

              {errors3.type_document ? (
                <span className="error">Insira um tipo valido</span>
              ) : null}
            </ContainerInput>
          </FormFullFirst>

          <FormFull>
            <ContainerInput isEditing={true}>
              <FormLabel>
                <h2>Tipo do documento</h2>
              </FormLabel>

              <div className="radio">
                <Radio.Group onChange={onChangeRadio} value={value}>
                  <Radio value={1}>CPF</Radio>
                  <Radio value={2}>CNPJ</Radio>
                </Radio.Group>
              </div>

              {/* {errors3.type_document ? (
                        <span className="error">Insira um tipo valido</span>
                      ) : null} */}
            </ContainerInput>
          </FormFull>

          <FormFull>
            <ContainerInput>
              <FormLabel>
                {value === 1 ? <h2>CPF</h2> : null}

                {value === 2 ? <h2>CNPJ</h2> : null}
              </FormLabel>

              {value === 1 ? (
                <input {...register3("document")} onKeyUp={handleKeyUpCpf} />
              ) : null}

              {value === 2 ? (
                <input {...register3("document")} onKeyUp={handleKeyUpCnpj} />
              ) : null}

              {errors3.document ? (
                <span className="error">Insira um documento válido</span>
              ) : null}
            </ContainerInput>
          </FormFull>

          <ContainerNormalButtons>
            <ButtonCancel type="button" onClick={() => setRefoundType(null)}>
              <span>Voltar</span>
            </ButtonCancel>

            <ButtonCancelPurchase
              active
              type="submit"
              isLoading={isSubmitting3}
            >
              <Spin spinning={isSubmitting3} indicator={antIcon} />

              <span>Confirmar</span>
            </ButtonCancelPurchase>
          </ContainerNormalButtons>
        </ContainerModalAddDocument>
      ) : null}

      {completeCancel ? (
        <ContainerModalAddDocument>
          <Center>
            <img src={checkCancelPurchaseIcon} alt="cancel" />

            <h2 className="title">Compra cancelada!</h2>

            <p className="description">
              O valor da compra de ID: {purchaseId} foi estornado e estará
              disponível na conta bancária selecionada em até 7 dias úteis.
            </p>

            <button className="ok" type="button" onClick={handleResetAndOk}>
              <span>Ok</span>
            </button>
          </Center>
        </ContainerModalAddDocument>
      ) : null}
    </Modal>
  );
}
