import { Colors } from "constants/colors";

import {
  Container,
  Card,
  Tr,
  Status,
  ContainerItemsMobile,
  CardMobile,
  List,
  Item,
  LinkStyled,
  LinkStyledMobile,
  TrExtra,
  TrBody,
} from "./styles";

import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useState } from "react";
import { EventType } from "types";
import Skeleton from "react-loading-skeleton";

import { StatusComponent } from "components/Status";
import { Link } from "react-router-dom";
import { HeaderFilterComponent } from "components/HeaderFilter";
import { ADVANCED_FILTERS, SIMPLE_FILTERS } from "constants/filters/events";
import { ADMIN_STATUS, ADMIN_STATUS_COLORS } from "constants/adminStatus";

interface TableProps {
  data?: EventType[];
  total: number | null;
}

export function Table({ data, total }: TableProps) {
  const [activeEvent, setActiveEvent] = useState<EventType | null>(null);

  function handleSelectedUser(event: EventType, active: boolean) {
    if (active) {
      setActiveEvent(event);

      return;
    }

    setActiveEvent(null);
  }

  return (
    <Container>
      <Card>
        <HeaderFilterComponent
          ADVANCED_FILTERS={ADVANCED_FILTERS}
          SIMPLE_FILTERS={SIMPLE_FILTERS}
          total={total ?? 0}
          label="do evento"
        />

        <section>
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>DATA DE CRIAÇÃO</th>
                <th>NOME DO EVENTO</th>
                <th>ORGANIZADOR</th>
                <th>TIPO</th>
                <th>STATUS</th>
                <th> </th>
              </tr>
            </thead>
            <tbody>
              {!data
                ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                    <Tr hasAdminStatus={false} active={false}>
                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>

                      <td>
                        <Skeleton height={30} width={60} />
                      </td>
                    </Tr>
                  ))
                : null}

              {data?.map((event, i) => (
                <>
                  <Tr
                    hasAdminStatus={!!event?.admin_status}
                    key={i}
                    active={event?._id === activeEvent?._id}
                  >
                    <td>
                      <div className="all">
                        <Link to={`/events/${event._id}`}>
                          <strong>{event._id}</strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <strong>{event?.formattedCreatedAt}</strong>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <Link to={`/events/${event._id}`}>
                          <strong>{event.name}</strong>
                        </Link>
                      </div>
                    </td>

                    <td className="little">
                      <div className="all">
                        <Link to={`/organizers/${event.organizer}`}>
                          <strong>{event.organizer.name}</strong>
                        </Link>
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        {event?.type_event === 1 ? (
                          <strong>Presencial</strong>
                        ) : null}

                        {event?.type_event === 2 ? (
                          <strong>Online </strong>
                        ) : null}

                        {event?.type_event === 3 ? (
                          <strong>Híbrido (Presencial + Online) </strong>
                        ) : null}
                      </div>
                    </td>

                    <td>
                      <div className="status-wrapper">
                        <div className="all">
                          <strong>Status organizador</strong>
                          <StatusComponent
                            content={event.status ? "Ativo" : "Inativo"}
                            color={event.status ? "green" : "red"}
                          />
                        </div>

                        {!!event?.admin_status && (
                          <div className="all">
                            <strong>Status administrador</strong>
                            <StatusComponent
                              content={
                                ADMIN_STATUS.find(
                                  (i) =>
                                    i.value ===
                                    event?.admin_status.toLocaleLowerCase()
                                )?.label ?? ""
                              }
                              color={
                                ADMIN_STATUS_COLORS.find(
                                  (i) =>
                                    i.value ===
                                    event?.admin_status.toLocaleLowerCase()
                                )?.color ?? ""
                              }
                            />
                          </div>
                        )}
                      </div>
                    </td>

                    <td>
                      <div className="all">
                        <div className="column">
                          {activeEvent?._id === event._id ? (
                            <FiChevronUp
                              onClick={() => handleSelectedUser(event, false)}
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          ) : (
                            <FiChevronDown
                              onClick={() => handleSelectedUser(event, true)}
                              style={{ cursor: "pointer" }}
                              color={Colors.secondary80}
                              size={18}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </Tr>

                  {activeEvent?._id === event._id ? (
                    <TrExtra>
                      <th>ORGANIZADOR</th>
                      <th>PRIVACIDADE</th>
                      <th>INICIO</th>
                      <th>TÉRMINO</th>
                      <th>LINK</th>
                      <th>TICKETS</th>
                      <th> </th>
                    </TrExtra>
                  ) : null}

                  {activeEvent?._id === event._id ? (
                    <TrBody
                      key={i}
                      active
                      hasAdminStatus={!!event?.admin_status}
                    >
                      <td>
                        <div className="column-one">
                          <strong className="first">
                            <Link to={`/organizers/${event.organizer?._id}`}>
                              <b>{event.organizer?.name ?? "Não informado"}</b>
                            </Link>
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            {event.privacity === "1" ? "Público" : "Privado"}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            {event.formattedBeginDate}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="first">
                            {event.formattedEndDate}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column-one">
                          <strong className="final">
                            <Link
                              to={`${process.env.REACT_APP_EVENTX_WEBSITE}/${event.nickname}`}
                            >
                              {event.nickname}
                            </Link>
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="all">
                          <strong>
                            {event?.tickets ? event?.tickets.length : 0}
                          </strong>
                        </div>
                      </td>

                      <td>
                        <div className="column">
                          <LinkStyled
                            to={`/events/${event._id}`}
                            hasAdminStatus={!!event?.admin_status}
                          >
                            <span>Detalhes</span>
                          </LinkStyled>
                        </div>
                      </td>
                    </TrBody>
                  ) : null}
                </>
              ))}
            </tbody>
          </table>
        </section>

        {data && data.length === 0 ? (
          <div className="container-empty">
            <h3>Nenhum Evento Encontrado</h3>
          </div>
        ) : null}
      </Card>

      <ContainerItemsMobile>
        <CardMobile>
          <HeaderFilterComponent
            ADVANCED_FILTERS={ADVANCED_FILTERS}
            SIMPLE_FILTERS={SIMPLE_FILTERS}
            total={total ?? 0}
            label="do evento"
          />

          <List>
            {!data
              ? [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((_) => (
                  <Item>
                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>

                    <div className="skeleton">
                      <Skeleton height={20} width={220} />
                    </div>
                  </Item>
                ))
              : null}

            {data?.map((event) => (
              <Item>
                <h1 className="first">{event.name}</h1>

                <span className="text">
                  Ingressos totais: {event.tickets ? event.tickets.length : 0}
                </span>

                <span className="text">
                  Data de criação: {event?.formattedCreatedAt}
                </span>

                <div className="chevron-mobile">
                  <Status>
                    <span> Ativo</span>
                  </Status>

                  {activeEvent?._id === event._id ? (
                    <FiChevronUp
                      onClick={() => handleSelectedUser(event, false)}
                      style={{ cursor: "pointer" }}
                      color={Colors.secondary80}
                      size={22}
                    />
                  ) : (
                    <FiChevronDown
                      onClick={() => handleSelectedUser(event, true)}
                      style={{ cursor: "pointer" }}
                      color={Colors.secondary80}
                      size={22}
                    />
                  )}
                </div>

                {activeEvent?._id === event._id ? (
                  <div className="footer-details">
                    <LinkStyledMobile to={`/events/${event._id}`}>
                      <span>Detalhes</span>
                    </LinkStyledMobile>
                  </div>
                ) : null}
              </Item>
            ))}
          </List>
        </CardMobile>
      </ContainerItemsMobile>
    </Container>
  );
}
